import React from 'react';
import { createRoot } from 'react-dom/client';

import Providers from './Providers';
import App from './Main';

const container = document.getElementById('app');

if (container) {
  const root = createRoot(container);
  root.render(
    <Providers>
      <App />
    </Providers>
  );
}

import React from 'react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';

import type { GoogleCredentialsDecoded } from './type';

export default function App() {
  const [name, setName] = React.useState<string | undefined>();
  const [sub, setSub] = React.useState<string | undefined>();

  const handleLoginSuccess = (credentialResponse: CredentialResponse) => {
    if (credentialResponse.credential) {
      const decoded = jwtDecode<GoogleCredentialsDecoded>(credentialResponse.credential);
      setName(decoded.given_name);
      setSub(decoded.sub);
    }
  };

  const handleLoginError = () => {

  };

  if (!name) {
    return (
      <div className="h-screen w-screen flex justify-center items-center">
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginError}
          useOneTap
        />
      </div>
    );
  }

  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <div className="text-center">
        <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
          Hi {name}!
        </h1>
        <p className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
          Your Google ID is {sub}!
        </p>
      </div>
    </div>
  );
}

import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function Providers({ children }: React.PropsWithChildren) {
  return (
    <GoogleOAuthProvider clientId="1010748353583-rldlav5s9kn6j03ho649belbjbo7i4rf.apps.googleusercontent.com">
      {children}
    </GoogleOAuthProvider>
  );
}
